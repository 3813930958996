import React, { useState, useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { Col, Row, Form } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next';
import { postContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import { nutrientNumberCellStyle } from '../ComponentStyles/ButtonStyles.js'

import '../../css/general.css'
import '../../css/farmPlot.css'

const StorageInfoModal = (props) => {
    const { auth } = useContext(AppContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState("");
    const [storageData, setStorageData] = useState(null);

    useEffect(() => {
        setTitle(t('Storage'));
        setStorageData(props.selectEvent.selectedElement);
        setIsLoading(false);
    }, [props.selectEvent.storage]);

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                    <div>
                            {!storageData.purchaseProducts ? <p>{t('Storage_Empty')}</p> :
                                <Form>
                                    {/* HEADERS */}
                                    <div>
                                        <Form.Group className="nutrient-table-header">
                                            <Row className="nutrient-table-title" >
                                                <Col xs={10}>
                                                    { storageData.name }
                                                </Col>
                                            </Row>
                                            <Row className="storage-header-row" >
                                                <Col xs={6} className="storage-row">
                                                    <b>{t('Product')}</b>
                                                </Col>
                                                <Col xs={6} className="storage-row" style={nutrientNumberCellStyle}>
                                                    <b>{t('Inventory_Amount')}</b>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </div>

                                    <Form.Group className="nutrient-table" key={'001'}>
                                        {storageData.purchaseProducts.map((product, i) => (
                                            <Row className="nutrient-data-row" key={i}>
                                                <Col xs={8} className="storage-row">
                                                    {product.name}
                                                </Col>
                                                <Col xs={4} className="storage-row" style={nutrientNumberCellStyle}>
                                                    {product.amount}  {product.unitText}
                                                </Col>
                                            </Row>
                                        ))}
                                    </Form.Group>
                                </Form>
                            }
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StorageInfoModal;