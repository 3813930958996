import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert'
import { getSelectedLang, getSelectedYear, getSelectedFarmId, formatGroupLabel } from '../../common';
import { valueMax } from '../../commonFunctions';
import Spinner from 'react-bootstrap/Spinner';
import '../../css/general.css'
import '../../css/inventory.css';
import { getContentWithAction, postContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import ConfirmModal from '../Alerts/ConfirmModal';

const InventoryManagementModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const alert = useAlert();
    const [isLoading, setIsLoading] = useState(true);
    const [storages, setStorages] = useState([]);
    const [products, setProducts] = useState([]);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [selectedStorage, setSelectedStorage] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [preSelectedStorage, setPreSelectedStorage] = useState(null); // Used as temp for checking if user has made changes

    useEffect(() => {
        retrieveModalData();
    }, []);

    const retrieveModalData = () => {
        var farmId = getSelectedFarmId();
        var year = getSelectedYear();
        var lang = getSelectedLang();

        Promise.all([
            getContentWithAction(auth.getAccessToken(), 'plotOperation', 'getMMWisuStorages', { farmId, year, lang }),
            // session
            getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {})
        ]).then(([storages, session]) => {
            responseHandler(storages);
            setIsLoading(false);
        }).catch((c) => { setIsLoading(false); });
    }

    const selectStorage = (selectedStorage) => {
        if (hasChanges) {
            setPreSelectedStorage(selectedStorage);
            setShowConfirm(true);
        }
        else {
            setSelectedStorage(selectedStorage);
            var copyProducts = [...selectedStorage.purchaseProducts].map(x => ({ ...x }));
            setProducts(copyProducts);
        }
    }

    const responseHandler = (response) => {
        var storageOptions = [];
        var value = 0;
        response.storages.forEach(s => {
            if (s.propertyType === 2) {
                s.name = t('Inventory_Seeds');
            }
            else if (s.propertyType === 3) {
                s.name = t('Inventory_Fertilizers');
            }
            else if (s.propertyType === 4) {
                s.name = t('Inventory_Pesticides');
            }
            storageOptions.push({ ...s, label: s.name, value: value });
            value++;
        });
        setStorages(storageOptions);

        if (storageOptions) {
            setSelectedStorage(storageOptions[0]);
            var copyProducts = [...storageOptions[0].purchaseProducts].map(x => ({...x}));
            setProducts(copyProducts);
        }
    }

    const getProductName = (product) => {
        if (product.operationType === 0) {
            // Seeds
            var organic = product.organic ? t('Inventory_Organic') : t('Inventory_Regular');
            var qua = "";
            if (product.quality === 6) qua = t('Inventory_Certified');
            else if (product.quality === 16) qua = t('Inventory_Farm_Seed');
            else if (product.quality === 17) qua = t('Inventory_Other');
            var productName = product.name;

            return productName + " " + qua + " " + organic;
        }
        else if (product.operationType === 1) {
            // Fertilizers
            return product.name;
        }
        else if (product.operationType === 2) {
            // Pesticides
            return product.name;
        }
        else if (product.operationType === 3) {
            // Harvests / Silo products
            return product.name;
        }

        return product.productCode;
    }

    const saveChanges = () => {
        setSaveInProgress(true);

        // Only save products that have changes
        var filteredProducts = products.filter(p => p.saveChanges === true).map(p => ({ ...p }));
        var data = [...filteredProducts];
        data.forEach(d => {
            // Amount value is the one that is saved to database, setting it here so it doesn't mess with UI value
            d.amount = d.calculated ? d.calculated - d.amount : 0;
        });

        postContentWithAction(auth.getAccessToken(), 'plotOperation', 'saveInventory', {models: data}).then(result => {
            setSaveInProgress(false);
            alert.show(t('SavedSuccesfully'), { type: 'success' });
            props.onHide();
            props.refresh();
        }).catch((c) => {
            // show alert
            setSaveInProgress(false);
            alert.show(t('SavedFailed'), { type: 'error' });
        });
    }

    const addProduct = () => {
        console.log("__TODO: ADDING PRODUCT__");
    }

    const addToSilos = () => {
        console.log("__TODO: ADDING TO SILOS__");
    }

    const updateAmount = (event, product) => {
        var productsCopy = [...products];
        var index = productsCopy.indexOf(product);

        // Check if calculated amount is 0, then disable saving for this product
        if (!event.floatValue) {
            productsCopy[index].calculated = event.floatValue;
            productsCopy[index].saveChanges = false;
            productsCopy[index].additionalInfo = '';
            setProducts(productsCopy);

            // if none of the products don't have calculated amount, then disable saving
            var saveChanges = products.some(p => p.calculated);
            setHasChanges(saveChanges);
            return;
        }

        setHasChanges(true);
        productsCopy[index].calculated = event.floatValue;
        productsCopy[index].saveChanges = true;
        setProducts(productsCopy);
    }

    const updateDescription = (event, product) => {
        var productsCopy = [...products];
        var index = productsCopy.indexOf(product);
        productsCopy[index].calculated ? productsCopy[index].additionalInfo = event.target.value : productsCopy[index].additionalInfo = '';
        setProducts(productsCopy);
    }

    const getInventorySumValue = (product) => {
        var calculatedValue = product.calculated ? product.calculated - product.amount : null
        if (!calculatedValue) return "";
        var valueString = "";
        valueString = calculatedValue > 0 ? "+" + calculatedValue : calculatedValue;
        return valueString + " " + product.unitText;
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('Inventory')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                        <Form>
                            <Form.Group controlId="formPlotModification">
                                <Form.Label className="text-dark">{t('Select')}</Form.Label>
                                <Select
                                    className={"text-dark"}
                                    value={selectedStorage}
                                    onChange={(storage) => selectStorage(storage)}
                                    options={storages}
                                    formatGroupLabel={formatGroupLabel}
                                />
                            </Form.Group>
                            {products ? products.map((product, index) =>
                                <div key={index} className="product-box border-top">
                                    <Form.Group className="inventory-product-header-row" controlId="formPlotModification">
                                        <Form.Label className="text-dark">{getProductName(product)}</Form.Label>
                                    </Form.Group>
                                    <div className="inventory-row">
                                        <Form.Group controlId="formPlotModification">
                                            <Form.Label className="text-dark inventory-label">{t('Inventory_Amount')}</Form.Label>
                                            <NumberFormat
                                                disabled
                                                className="form-control inline-form-field"
                                                displayType={'input'}
                                                suffix={'  ' + product.unitText}
                                                placeholder={'  ' + product.unitText}
                                                thousandSeparator={' '}
                                                allowedDecimalSeparators={['.', ',']}
                                                value={product.amount}
                                                isAllowed={valueMax}
                                                onChange={e => { }} />
                                        </Form.Group>
                                        <Form.Group controlId="formPlotModification">
                                            <Form.Label className="text-dark inventory-label">{t('Inventory_Calculated')}</Form.Label>
                                            <NumberFormat
                                                className="form-control inline-form-field"
                                                suffix={'  ' + product.unitText}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                allowedDecimalSeparators={['.', ',']}
                                                value={product.calculated ? product.calculated : ''}
                                                isAllowed={valueMax}
                                                onValueChange={e => {
                                                    updateAmount(e, product);
                                                }} />
                                        </Form.Group>
                                        <Form.Group controlId="formPlotModification">
                                            <Form.Label className="text-dark inventory-label">{t('Inventory_Description')}</Form.Label>
                                            <Form.Control disabled={!product.saveChanges} className="form-control inline-form-field" type="text" value={product.additionalInfo ?? ''} onChange={e => { updateDescription(e, product) }} />
                                        </Form.Group>
                                        <Form.Group controlId="formPlotModification">
                                            <Form.Label className="text-dark inventory-label">{t('Inventory_Sum')}</Form.Label>
                                            <Form.Text className="inventory-sum-row inline-form-field" type="text" onChange={e => { }}>{getInventorySumValue(product)}</Form.Text>
                                        </Form.Group>
                                    </div>

                                </div>
                            ) : ''}
                            <div className="inventory-buttons" hidden>
                                <Button variant="outline-secondary" onClick={() => { addProduct(); }}>+ {t('Add_Inventory_Product')}</Button>
                            </div>
                            <div className="inventory-buttons" hidden>
                                <Button variant="outline-secondary" onClick={() => { addToSilos(); }}>+ {t('Add_To_Silos')}</Button>
                            </div>
                        </Form>
                    }
                </Modal.Body>   
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Close')}</Button>
                    <Button variant="success" disabled={!hasChanges || saveInProgress || isLoading} className="btn-show-more" onClick={() => { saveChanges(); }}>{saveInProgress ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : t('Save')}</Button>
                </Modal.Footer>
            </Modal>

            {showConfirm === true ?
                <ConfirmModal
                    show={showConfirm}
                    accept={() => {
                        setShowConfirm(false);
                        setSelectedStorage(preSelectedStorage);
                        var copyProducts = [...preSelectedStorage.purchaseProducts].map(x => ({ ...x }));
                        setProducts(copyProducts);
                        setHasChanges(false);
                    }}
                    decline={() => {
                        setShowConfirm(false);
                        setPreSelectedStorage(null);
                    }}
                    onHide={() => setShowConfirm(false)}
                    title={t('DeleteInventory')}
                />
                : ''}
        </>
    )
}

export default InventoryManagementModal;