import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import '../../css/general.css'
import AppContext from '../../AppContext';
import { postContentWithAction } from '../../apiConnect';
import NumberFormat from 'react-number-format';
import Spinner from 'react-bootstrap/Spinner';

const SiloModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);
    const [silo, setSilo] = useState(props.defaultName ? props.defaultName : '');
    const [capacity, setCapacity] = useState(0);
    const [volume, setVolume] = useState(0);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [existingStorages, setExistingStorages] = useState([]);
    const [invalidName, setInvalidName] = useState(false);

    useEffect(() => {
        setExistingStorages(props.existingStorages);
    }, [props.existingStorages]);

    const checkSiloName = (name) => {
        var exists = false;
        for (var i = 0; i < existingStorages.length; i++) {
            if (existingStorages[i].name.toLowerCase() === name.toLowerCase()) {
                exists = true;
                break;
            }
        }
        if (exists) setInvalidName(true);
        else setInvalidName(false);
        setSilo(name);
    }

    return (
        <Modal
            size='sm'
            show={props.showSilo} onHide={props.onHideSilo} centered className="mobi-calendar-content harvest-silo-modal">
            <Modal.Header closeButton>
                <Modal.Title className="text-dark">{t('AddNewStorage')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="silo-name-div">

                    <Form.Group controlId="formAdditionalInformation">
                        <Form.Label className="text-dark">{t('Name')}:</Form.Label>
                        <Form.Control className={invalidName ? "invalid-border" : ""} type="text"
                            value={silo}
                            onChange={(e) => {
                                checkSiloName(e.currentTarget.value);
                            }}
                            placeholder='' />
                    </Form.Group>

                    <Form.Group controlId="formAdditionalInformation">
                        <Form.Label className="text-dark">{t('Volume')}:</Form.Label>
                        <NumberFormat
                            className={"form-control"}
                            value={volume}
                            placeholder={' m3'}
                            decimalScale={2}
                            onValueChange={(values) => {
                                if (values.floatValue !== null) {
                                    setVolume(values.floatValue);
                                }
                            }}
                            displayType={'input'}
                            thousandSeparator={' '}
                            suffix={' m3'}
                            allowNegative={false}
                            allowedDecimalSeparators={['.', ',']} />

                    </Form.Group>

                    <Form.Group controlId="formAdditionalInformation">
                        <Form.Label className="text-dark">{t('Capacity')}:</Form.Label>
                        <NumberFormat
                            className={"form-control"}
                            value={capacity}
                            placeholder={' kg'}
                            decimalScale={2}
                            onValueChange={(values) => {
                                if (values.floatValue !== null) {
                                    setCapacity(values.floatValue);
                                }
                            }}
                            displayType={'input'}
                            thousandSeparator={' '}
                            suffix={' kg'}
                            allowNegative={false}
                            allowedDecimalSeparators={['.', ',']} />

                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-footer-right-align">
                <Button variant="outline-secondary" onClick={props.onHideSilo}>{t('Close')}</Button>
                {props.buttonWriteShow === true ?
                    <Button variant="success" disabled={saveInProgress || invalidName} className="btn-show-more" onClick={() => {
                        if (invalidName) return;
                        setSaveInProgress(true);
                        postContentWithAction(auth.getAccessToken(), 'harvest', 'newStorage',
                            {
                                name: silo,
                                product: Number(props.selectedProduct).toString(),
                                volume: volume,
                                capacity: capacity
                            },
                            (newId) => {
                                setSaveInProgress(false);
                                props.saveSilo(newId);
                                props.onHideSilo();
                            }).catch(e => {
                                console.log("ERROR!");
                                setSaveInProgress(false);
                            });

                    }}>{saveInProgress ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : t('Save')}</Button>
                : ''}
            </Modal.Footer>
        </Modal>
    );
}

export default SiloModal;