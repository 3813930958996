import React, { useState, useEffect, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { useTranslation } from 'react-i18next';
import AppContext from '../../AppContext';

import { getContentWithAction, postContentWithAction } from '../../apiConnect';
import { getSelectedPlot } from '../../common';
import { textStyle, rowStyle, addNoteRowStyle, addButtonStyle, nutrientRowStyle } from '../ComponentStyles/ButtonStyles.js'
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import '../../css/plotOperation.css'

import * as Cards from '../../Constants/AccordionCardConstants.js';
import { toggleActive, formatPlannedDate, selectFertilizerName, dayJsFormatDate } from '../../commonFunctions.js'
import { OperationCardToggle } from '../../commonElements.js'

const FertilizeAccordionCard = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [fertilizes, setFertilizes] = useState([]);
    const [memos, setMemos] = useState([]);
    const [fertilizeIsLoading, setFertilizeIsLoading] = useState(true);
    const [memosIsLoading, setMemosIsLoading] = useState(true);

    const p = getSelectedPlot();

    useEffect(() => {
        setFertilizeIsLoading(true);

        if (p && p.apNumber) {
            getContentWithAction(auth.getAccessToken(), 'fertilize', 'getFertilizings', { plotId: p.apNumber }).then((fertilizes) => {
                if (getSelectedPlot() && getSelectedPlot().apNumber) {
                    fertilizes = fertilizes.filter(f => f.plotId === getSelectedPlot().apNumber);
                    setFertilizes(fertilizes);
                }
                setFertilizeIsLoading(false);
            });
        }
    }, [props.refresh]);

    useEffect(() => {
        setMemosIsLoading(true);
        var memos = props.memos.filter(m => m.plantMemoType === 2);
        memos.sort((a, b) => b.memo > a.memo ? -1 : 1);
        setMemos(memos);
        setMemosIsLoading(false);
    }, [props.memos])

    const setFertilizeDone = (fert) => {
        var id = fert.fertilizingId;
        var plotId = fert.plotId;
        var fertArea = fert.area ?? p.area;
        // Updating individual sow to show spinner
        const updatedFerts = fertilizes.map(s => s === fert ? { ...s, saveInProgress: true } : s);
        setFertilizes(updatedFerts);
        postContentWithAction(auth.getAccessToken(), 'fertilize', 'done', { id: id, plotId: plotId, fertilizationArea: fertArea }, () => {
            // Updating individual sow to show spinner
            const updatedFerts = fertilizes.map(s => s === fert ? { ...s, saveInProgress: true } : s);
            setFertilizes(updatedFerts);
            setFertilizeIsLoading(true); props.setRefresh(Date.now());
        });
    }

    const getRowLetter = (row) => {
        return selectFertilizerName(row).substring(0, 1);
    }

    //const deleteMemo = (memo, plotId) => {
    //    postContentWithAction('memo', 'delete', { memoId: memo.memoId, plotId: plotId }, () => {
    //        setFertilizeIsLoading(true);
    //        props.setRefresh(Date.now());
    //    });
    //}

    return (
        <Card className="border-bottom-1">
            <Card.Header className={props.activeCard === Cards.fertilizingCardId ? 'active-panel' : 'inactive-panel'}>
                <OperationCardToggle classname={props.activeCard === Cards.fertilizingCardId ? 'active-panel' : 'inactive-panel'} callback={() => toggleActive(props.activeCard, Cards.fertilizingCardId, props.setActiveCard)} eventKey="1">
                    {t('Fertilizinglist')}
                    {fertilizeIsLoading ? <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" /> : <Badge pill bg='light' text='dark' className="badge-pill">{fertilizes.length}</Badge>}
                </OperationCardToggle>
                <Button style={addButtonStyle} variant='mmfi-add-btn' className="float-right" onClick={() => {
                    props.setSelectEvent({ time: Date.now(), selectedElement: null });
                    props.setModalShow(true);
                }}><Icon icon={ICONS.ADD} color='white' size='16' /></Button>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
                <Card.Body>
                    <ListGroup>
                        {(fertilizes) ? fertilizes.map(p =>
                            <div key={p.fertilizingId} id={p.fertilizingId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-operation'>{getRowLetter(p)}</div>

                                <ListGroup.Item key={p.fertilizingId} style={rowStyle} className={!p.date ? "plot-operation-row" : "plot-operation-row-done"} action onClick={() => {
                                    props.setSelectEvent({ time: Date.now(), selectedElement: p });
                                    props.setModalShow(true)
                                }}>

                                    <div><span className="operation-list-name">{selectFertilizerName(p)}</span> - {p.amount}  {p.unitText}/ha
                                        {(p.pesticideId) ?
                                            <span className="tank-mixture-icon" title={t('TankMixture')}><Icon icon={ICONS.INFO} color='#2ba3c4' size='20' /></span>
                                            : ''}
                                    </div>

                                    <div>{(p.date) ?
                                        <span className='row-subtext'>{t('Completed')} {dayJsFormatDate(p.date)}</span>
                                        : <span className='row-subtext'>{formatPlannedDate(p.plannedDate, t)}</span>}
                                    </div>
                                </ListGroup.Item>

                                {props.allowedSave ?
                                    <div className="set-done-box">
                                        {p.saveInProgress ? <Spinner className="mark-spin" as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                                        {!p.date ? <Button variant='success' className={p.saveInProgress ? "hidden" : "float-right set-done fert-list-mark-mobile"} onClick={() => setFertilizeDone(p)}>{t('MarkDoneShort')}</Button> : ''}
                                        {!p.date ? <Button variant='success' className={p.saveInProgress ? "hidden" : "float-right set-done fert-list-mark-desktop"} onClick={() => setFertilizeDone(p)}>{t('MarkDone')}</Button> : ''}
                                    </div>
                                : ''}
                            </div>
                        ) : ''}
                        {!props.memosIsLoading ? memos.map(p =>
                            <div key={p.memoId} className="row-margin-bot">
                                <div className='row-icon-left row-icon-memo'><Icon icon={ICONS.EDIT} color='white' size='20' /></div>
                                <ListGroup.Item className="plot-memo-row" key={p.memoId} style={rowStyle} action onClick={() => {
                                    props.setMemoType(2);
                                    props.setSelectMemoEvent({ time: Date.now(), selectedElement: p });
                                    props.setMemoModalShow(true);
                                }}>
                                    <div><b> {t('Note')}</b></div>
                                    <div className='row-subtext'>{p.memo}</div>
                                </ListGroup.Item>
                                <span style={textStyle}></span>
                            </div>
                        ) : <div>
                            {t('Loding')}
                            <Spinner className="badge-loading" as="span" animation="border" size="sm" role="status" variant="secondary" />
                        </div>}

                        <ListGroup.Item style={addNoteRowStyle} action
                            onClick={() => {
                                props.setMemoType(2);
                                props.setSelectMemoEvent({ time: Date.now(), selectedElement: null });
                                props.setMemoModalShow(true);
                            }}>
                            <div className='add-note-icon'><Icon icon={ICONS.ADD} color='black' size='16' /></div>
                            <div>{t('AddNote')}</div>
                            {memosIsLoading ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                        </ListGroup.Item>

                        <ListGroup.Item className="fetch-nutrients" key={'nutrient_calculation_show'} style={nutrientRowStyle} action onClick={() => {
                            props.setSelectEvent({ time: Date.now(), selectedElement: null })
                            props.setNutrientModalShow(true);
                        }}>
                            <div className='nutrient-calculation-icon'><Icon icon={ICONS.STATS} color='white' size='22' /></div>
                            <div className='active-panel'>{t('Nutrient_Calculation_Show')}</div>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

export default FertilizeAccordionCard;