import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import NumberFormat from 'react-number-format';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs'
import Spinner from 'react-bootstrap/Spinner';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { ToggleButton } from 'react-bootstrap';
import { noInput } from '../ComponentStyles/InputStyles.js'
import { useAlert } from 'react-alert'
import { valueMax, valueMaxArea, valueMaxDryMaterialPercentage, dayJsFormatDate, round } from '../../commonFunctions';
import AppContext from '../../AppContext';

import '../../css/general.css'
import '../../css/plotOperation.css'

import CalendarModal from './CalendarModal';
import SiloModal from './SiloModal';
import { getContentWithAction, postContentWithAction, putContentWithAction } from '../../apiConnect';
import { getSelectedPlot, formatGroupLabel } from '../../common';
import * as harvestConstants from '../../Constants/HarvestConstants.js';

const HarvestModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [pageIsLoading, setPageIsLoading] = useState(true);
    const [calendarShow, setCalendarShow] = useState(false);
    const [siloShow, setSiloShow] = useState(false);

    const [products, setProducts] = useState([]);
    const [productsIsLoading, setProductsIsLoading] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [storages, setStorages] = useState([]);
    const [drawStorages, setDrawStorages] = useState([]);

    const [storagesIsLoading, setStoragesIsLoading] = useState(true);
    const [selectedStorage, setSelectedStorage] = useState(0);
    const [harvestArea, setHarvestArea] = useState(0);
    const [amount, setAmount] = useState(0);
    const [amountArea, setAmountArea] = useState(0);
    const [totalArea, setTotalArea] = useState(0);
    const [date, setDate] = useState(dayjs().format());
    const [selectedHarvestUnitInputType, setSelectedHarvestUnitInputType] = useState(1);
    const [selectedHarvestUnit, setSelectedHarvestUnit] = useState('1');
    const [dryMateriaContent, setDryMateriaContent] = useState(0);
    const [unitPcs, setUnitPcs] = useState(0);
    const [freshContent, setFreshContent] = useState(0);
    const [trailerVolume, setTrailerVolume] = useState(0);
    const [volumeWeight, setVolumeWeight] = useState(0);
    const [validHarvestArea, setValidHarvestArea] = useState(false);

    const [dryMateriaPerHa, setDryMateriaPerHa] = useState(0);
    const [multiSelect, setMultiSelect] = useState(false);
    const [unit, setUnit] = useState('kg');
    const [validate, setValidation] = useState(false);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // allowed save btn disabled
    const [saveInProgress, setSaveInProgress] = useState(false);

    const textt = (t('ChooseStorage'));
    const textt2 = (t('ChooseProduct'));

    const alert = useAlert();
    let selectedPlot = useRef(getSelectedPlot());

    const harvestUnitsInputType = [
        { name: t('UnitKg'), value: 1 },
        { name: t('UnitKgDM'), value: 2 }
    ]

    const harvestUnitsGroup = [
        { name: t('UnitHarvestingSmallBale'), value: harvestConstants.SMALL_BALE_UNIT },
        { name: t('UnitHarvestingRoundBale'), value: harvestConstants.ROUND_BALE_UNIT },
        { name: t('UnitHarvestingCubicBale'), value: harvestConstants.CUBIC_BALE_UNIT },
        { name: t('UnitHarvestingWagon'), value: harvestConstants.WAGON_UNIT }
    ]

    useEffect(() => {
        !productsIsLoading && !storagesIsLoading ? setPageIsLoading(false) : setPageIsLoading(true);
    }, [productsIsLoading, storagesIsLoading])

    useEffect(() => {
        formatFields();
        let plantId = 0;
        setProductsIsLoading(true);
        setStoragesIsLoading(true);

        if (props.selectEvent.selectedPlots) { // If multiple chosen plots
            setMultiSelect(true);

            setTotalArea(props.selectEvent.selectedPlots.reduce((total, currentItem) => total = total + currentItem.area, 0));
            plantId = props.selectEvent.selectedPlots[0].plantId;

        }
        else {
            plantId = getSelectedPlot().plantId
        }

        Promise.all([
            getContentWithAction(auth.getAccessToken(), 'resource', 'products', { plantId: plantId }),
            getContentWithAction(auth.getAccessToken(), 'harvest', 'harvestSuggestions', {}),
            getContentWithAction(auth.getAccessToken(), 'resource', 'storages', {}),
            // session
            getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {})
        ]).then(([products, harvestSuggestions, storages, sessionResult]) => {

            formatProductGroups(harvestSuggestions, products);


            /* setProducts(products);*/
            setProductsIsLoading(false);

            setStorages(storages);
            setDrawStorages(storages);
            setStoragesIsLoading(false);

            if (props.selectEvent.selectedElement)  // Editing
            {
                setValidation(true);
                responseHandler(props.selectEvent.selectedElement, products, storages);
            }
            else {
                var plot = getSelectedPlot();
                if (plot?.area) {
                    validateHarvestArea(plot.area);
                    setHarvestArea(plot.area);
                } else {
                    setHarvestArea(0);
                }
            }

            setButtonWriteShow(sessionResult);
        });

    }, [props.selectEvent]);

    const responseHandler = (data, plantProducts, storagesResource) => {
        if (!data || data.memo) {
            return;
        }

        //product        
        plantProducts ? setSelectedProduct(plantProducts.find(p => p.product === data.product)) : setSelectedProduct(data);
        //silo      
        setDrawStorages(storagesResource.filter(s => Number(s.product) === Number(data.product)));
        setSelectedStorage(storagesResource && data.storageId ? storagesResource.find(s => s.value === data.storageId) : data.storageId);

        if (!data.loadAmount && data.amount && data.unitNumber === harvestConstants.DRY_CONTENT_UNIT_TYPE) {
            setSelectedHarvestUnitInputType(2);
        }


        if (!data.suggestion) {
            setUnitPcs(data.loadAmount);
            setAmount(data.amount);
            if (data.area) {
                validateHarvestArea(data.area);
                setHarvestArea(data.area);
            }
        }
        if (!data.area) {
            var plot = getSelectedPlot();
            if (plot?.area) {
                validateHarvestArea(plot.area);
                setHarvestArea(plot.area);
            } else {
                setHarvestArea(0);
            }
        }

        setFreshContent(data.baleWeight);

        setDryMateriaContent(data.dryMateriaContent);
        setSelectedHarvestUnit(data.harvestedUnit);

        if (data.harvestedUnit === harvestConstants.WAGON_UNIT) {
            setTrailerVolume(data.volume);

            if (data.unitNumber === harvestConstants.DRY_CONTENT_UNIT_TYPE)
                setVolumeWeight(data.volumeWeight);
        }

        if (data.beginDate && props.selectEvent.selectedElement) {
            setDate(dayjs(data.beginDate).format());
        }
        else {
            setDate(dayjs(Date.now()).format());
        }

        if (data.unitText)
            setUnit(data.unitText || 'kg');
    }

    const formatProductGroups = (harvestSuggestions, products) => {

        let unique = [];

        harvestSuggestions = harvestSuggestions.filter(p => {
            var match = products.find(a => a.product === p.product);
            if (match) {
                p.value = match.value;
                p.label = match.label;
                p.unitText = match.unitText;
                p.explanation = match.explanation;
                p.suggestion = true;
                return true;
            }

            return false;
        });


        harvestSuggestions.sort((a, b) => {

            if (a.product === b.product) {
                if (a.farmYear > b.farmYear) {
                    return -1;
                }
                if (a.farmYear < b.farmYear) {
                    return 1;
                }
                if (a.farmYear === b.farmYear) {
                    if (dayjs(a.modificationDate).isAfter(dayjs(b.modificationDate))) {
                        return -1;
                    }
                    if (dayjs(b.modificationDate).isAfter(dayjs(a.modificationDate))) {
                        return 1;
                    }
                    if (dayjs(a.modificationDate).isSame(dayjs(b.modificationDate))) {
                        return -1;
                    }
                }
            }
            else {
                if (a.product > b.product) {
                    return 1;
                }
                if (a.product < b.product) {
                    return -1;
                }

            }
            return 0;
        });


        for (let i = 0; i < harvestSuggestions.length; i++) { // nested for loop
            for (let j = 0; j < harvestSuggestions.length; j++) {
                if (harvestSuggestions[i].harvestId !== harvestSuggestions[j].harvestId) {
                    if (harvestSuggestions[i].product === harvestSuggestions[j].product) {
                        if (harvestSuggestions[i].farmYear > harvestSuggestions[j].farmYear) {
                            if (!unique.some((e) => e.product === harvestSuggestions[i].product)) {
                                unique.push(harvestSuggestions[i]);
                            }
                            continue;
                        }
                        if (harvestSuggestions[i].farmYear < harvestSuggestions[j].farmYear) {
                            if (!unique.some((e) => e.product === harvestSuggestions[i].product)) {
                                unique.push(harvestSuggestions[i]);
                            }
                            continue;
                        }
                        if (harvestSuggestions[i].farmYear === harvestSuggestions[j].farmYear) {
                            if (dayjs(harvestSuggestions[i].modificationDate).isAfter(dayjs(harvestSuggestions[j].modificationDate))) {
                                if (!unique.some((e) => e.product === harvestSuggestions[i].product)) {
                                    unique.push(harvestSuggestions[i]);
                                }
                                continue;
                            }
                            if (dayjs(harvestSuggestions[j].modificationDate).isAfter(dayjs(harvestSuggestions[i].modificationDate))) {
                                if (!unique.some((e) => e.product === harvestSuggestions[i].product)) {
                                    unique.push(harvestSuggestions[j]);
                                }
                                continue;
                            }
                            if (dayjs(harvestSuggestions[i].modificationDate).isSame(dayjs(harvestSuggestions[j].modificationDate))) {
                                if (!unique.some((e) => e.product === harvestSuggestions[i].product)) {
                                    unique.push(harvestSuggestions[i]);
                                }
                                continue;
                            }
                        }
                        continue;
                    }
                    if (!unique.some((e) => e.product === harvestSuggestions[i].product)) {
                        unique.push(harvestSuggestions[i]);
                    }
                    if (!unique.some((e) => e.product === harvestSuggestions[j].product)) {
                        unique.push(harvestSuggestions[j]);
                    }
                }
            }
        }

        setProducts([
            { label: t('Previously_Used'), options: unique },
            { label: t('All'), options: products },
        ]);
    };

    const formatFields = () => {
        setAmount(0);
        setSelectedStorage(0);
        setSelectedProduct(0);
        setDate(dayjs(Date.now()).format());
        setSelectedHarvestUnit(harvestConstants.SMALL_BALE_UNIT);
    }

    const deleteHarvest = () => {
        setSaveInProgress(true);
        postContentWithAction(auth.getAccessToken(), 'harvest', 'delete', {
            harvestId: props.selectEvent.selectedElement.harvestId, plotId: getSelectedPlot().apNumber
        }, () => {
            props.setRefresh(Date.now());
            props.onHide();
            alert.show(t('SavedSuccesfully'), { type: 'success' });
            setSaveInProgress(false);
        }).catch(e => {
            alert.show(t('SavedFailed'), { type: 'error' });
            setSaveInProgress(false);
        });
    }

    const compareArea = (a, b) => {
        if (a.plotArea < b.plotArea)
            return 1;
        if (a.plotArea > b.plotArea)
            return -1;
        return 0;
    }

    const checkMultipleAndCalculateLoadAmounts = (loadNumber) => {
        // left to share with plots (everyone gets one)
        var loadPerPlot = loadNumber - props.selectEvent.selectedPlots.length;

        var newPlotArray = [];
        props.selectEvent.selectedPlots.forEach((plot) => {
            newPlotArray.push({
                plotArea: plot.area,
                plotId: plot.apNumber,
                loadAmount: 0,
                amount: 0
            });
        });

        newPlotArray.sort(compareArea);
        var selectedArea = newPlotArray.reduce((total, plot) => plot.plotArea + total, 0);

        // share first loads
        var addInStart = true;
        newPlotArray.forEach((plot, index) => {
            if (loadNumber <= index) addInStart = false;

            plot.loadAmount = addInStart ? 1 : 0
        });

        // share loads which left over 
        newPlotArray.forEach(plot => {
            var plotloads = 0;
            if (loadPerPlot > 0) {
                plotloads =
                    Math.floor((plot.plotArea / selectedArea) *
                        loadPerPlot); // area divided totalarea * perplotloads --> floor takes integer part
            }
            plot.loadAmount += plotloads;

        });

        // extras, add to first one
        var attachedLoads = newPlotArray.reduce((total, plot) => plot.loadAmount + total, 0); // attached loads
        if (newPlotArray.length && attachedLoads && loadNumber > 0) {
            newPlotArray[0].loadAmount += loadNumber - attachedLoads;
        }

        newPlotArray.forEach(plot => {

            //calculate new amount for plot
            let totalValue = calculateAmountFromLoadValues(plot);

            var newValue = totalValue
                ? Math.round((Number(totalValue)) * 100) / 100
                : 0;

            plot.amount = newValue;
        });

        return newPlotArray;
    }

    const calculateAmountFromLoadValues = (plot) => {
        return (selectedHarvestUnit === harvestConstants.WAGON_UNIT) ?
            ((plot.loadAmount * trailerVolume * volumeWeight) / plot.plotArea) * (dryMateriaContent / 100)
            : ((plot.loadAmount * freshContent) / plot.plotArea) * (dryMateriaContent / 100)
    }

    const saveHarvest = () => {
        let loadAmount = 0, baleWeight = 0, volume = 0, vWeight = 0, harvestedUnit = 0;
        let newAmount = amount;

        if (selectedProduct && ((selectedProduct.unitType && selectedProduct.unitType === harvestConstants.DRY_CONTENT_UNIT_TYPE) ||
            (selectedProduct.unitNumber && selectedProduct.unitNumber === harvestConstants.DRY_CONTENT_UNIT_TYPE)) && selectedHarvestUnitInputType === 1) {
            loadAmount = unitPcs;

            if (selectedHarvestUnit === harvestConstants.WAGON_UNIT) {
                volume = trailerVolume;
                vWeight = volumeWeight;

                //validate
                if (!dryMateriaContent || !vWeight || !trailerVolume || !unitPcs)
                    return;
            }
            else {
                baleWeight = freshContent;

                //validate
                if (!dryMateriaContent || !freshContent || !unitPcs)
                    return;
            }

            harvestedUnit = Number(selectedHarvestUnit);
            newAmount = dryMateriaPerHa;
        }

        //validate
        if (!newAmount) return;
        if ((!multiSelect && !harvestArea) || (multiSelect && !totalArea)) return;

        if (!selectedProduct || !selectedProduct.product) return;

        if (!selectedStorage || !selectedStorage.id) return;

        var newHarvests = [];

        setSaveInProgress(true);
        if (multiSelect) {

            let balesArr = undefined;
            if (selectedProduct && ((selectedProduct.unitType && selectedProduct.unitType === harvestConstants.DRY_CONTENT_UNIT_TYPE) ||
                (selectedProduct.unitNumber && selectedProduct.unitNumber === harvestConstants.DRY_CONTENT_UNIT_TYPE))) {

                if (loadAmount >= props.selectEvent.selectedPlots.length) {
                    balesArr = checkMultipleAndCalculateLoadAmounts(unitPcs);
                }
            }

            props.selectEvent.selectedPlots.forEach((plot) => {

                //if balesArr != null -> loadAmount and newAmount can be changed
                if (balesArr && balesArr.length) {
                    let baleinfo = balesArr.find(b => b.plotId === plot.apNumber);
                    if (!baleinfo || !baleinfo.amount) return;

                    newAmount = baleinfo.amount;
                    loadAmount = baleinfo.loadAmount;
                }

                newHarvests.push({
                    plotId: plot.apNumber,
                    product: selectedProduct.product,
                    area: plot.area,
                    amount: newAmount,
                    beginDate: date,
                    plannedDate: null,
                    storageId: selectedStorage && selectedStorage.id ? selectedStorage.id : Number(selectedStorage) > 0 ? Number(selectedStorage) : 0,
                    dryMateriaContent: dryMateriaContent,
                    harvestedUnit: Number(harvestedUnit),
                    hectoliterWeight: 0,
                    volume: volume,
                    volumeWeight: vWeight,
                    loadAmount: loadAmount,
                    baleWeight: baleWeight
                });
            });

            postContentWithAction(auth.getAccessToken(), 'harvest', 'new', {
                models: newHarvests
            }, () => {
                props.onHide();
                props.setRefresh(Date.now());
                alert.show(t('SavedSuccesfully'), { type: 'success' });
                setSaveInProgress(false);
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
                setSaveInProgress(false);
            });
        }

        else {
            if (!props.selectEvent.selectedElement) {
                newHarvests.push({
                    plotId: getSelectedPlot().apNumber,
                    product: selectedProduct.product,
                    area: harvestArea,
                    amount: newAmount,
                    beginDate: date,
                    plannedDate: null,
                    storageId: selectedStorage && selectedStorage.id ? selectedStorage.id : Number(selectedStorage) > 0 ? Number(selectedStorage) : 0,
                    dryMateriaContent: dryMateriaContent,
                    harvestedUnit: Number(harvestedUnit),
                    hectoliterWeight: 0,
                    volume: volume,
                    volumeWeight: vWeight,
                    loadAmount: loadAmount,
                    baleWeight: baleWeight
                });

                postContentWithAction(auth.getAccessToken(), 'harvest', 'new', {
                    models: newHarvests
                }, () => {
                    props.onHide();
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                    setSaveInProgress(false);
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                    setSaveInProgress(false);
                });
            }
            else {
                putContentWithAction(auth.getAccessToken(), 'harvest', 'update', {
                    harvestId: props.selectEvent.selectedElement.harvestId,
                    plotId: getSelectedPlot().apNumber,
                    product: selectedProduct.product,
                    area: harvestArea,
                    amount: newAmount,
                    beginDate: date,
                    plannedDate: null,
                    storageId: selectedStorage && selectedStorage.id ? selectedStorage.id : Number(selectedStorage) > 0 ? Number(selectedStorage) : 0,
                    dryMateriaContent: dryMateriaContent,
                    harvestedUnit: Number(harvestedUnit),
                    hectoliterWeight: 0,
                    volume: volume,
                    volumeWeight: vWeight,
                    loadAmount: loadAmount,
                    baleWeight: baleWeight
                }).then(() => {
                    props.onHide();
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                    setSaveInProgress(false);
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                    setSaveInProgress(false);
                });
            }
        }
    }

    const validateHarvestArea = (value) => {
        if (value === 0 || value > selectedPlot.current.area) {
            setValidHarvestArea(false);
        }
        else {
            setValidHarvestArea(true);
        }
    }

    const checkSaveDisabled = () => {
        if (multiSelect) {
            return false;
        }

        return validHarvestArea ? false : true;
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('Yield')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {pageIsLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> :
                        <Form>
                            <Form.Group controlId="formProduct">
                                <Form.Label className="text-dark">{t('Product')}</Form.Label>
                                {productsIsLoading ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                                <Select
                                    className={validate && (!selectedProduct || !selectedProduct.product) ? "text-dark invalid-border" : "text-dark"}
                                    style={{ fontWeight: 'bold' }}
                                    value={selectedProduct?.label ? selectedProduct : { label: textt2, value: null }}
                                    onChange={value => {
                                        setSelectedProduct(value);
                                        setDryMateriaContent(value.dryMateriaContent);

                                        if (value.unitText)
                                            setUnit(value.unitText || 'kg');

                                        let product = value.harvestId ? value.product : Number(value.product);

                                        let silos = storages.filter(s => Number(s.product) === product);
                                        setDrawStorages(silos);

                                        if (value.harvestId)
                                            responseHandler(value, null, silos);

                                    }}
                                    options={products}
                                    noOptionsMessage={({ inputValue }) => !inputValue ? t('NoProducts') : ''}
                                    formatGroupLabel={formatGroupLabel}
                                />
                            </Form.Group>
                            {selectedProduct && (selectedProduct.unitType === harvestConstants.DRY_CONTENT_UNIT_TYPE || selectedProduct.unitNumber === harvestConstants.DRY_CONTENT_UNIT_TYPE) ?
                                /* ----------- PRODUCT WITH KG KA ----------- */
                                <div>
                                    <Form.Group controlId="formUnitHarvestingInputType">
                                        <ButtonGroup className="harvesting-unit-type-btn-group">
                                            {harvestUnitsInputType.map((radio, i) => (
                                                <ToggleButton
                                                    key={i}
                                                    id={radio.name + i}
                                                    type='radio'
                                                    variant='outline-success'
                                                    name='radio'
                                                    value={radio.value}
                                                    checked={selectedHarvestUnitInputType === radio.value}
                                                    onChange={(e) => setSelectedHarvestUnitInputType(Number(e.currentTarget.value))}
                                                >
                                                    {radio.name}
                                                </ToggleButton>
                                            ))}
                                        </ButtonGroup>
                                    </Form.Group>
                                    {/* ----------- UNITTYPE: KG -> show load amounts ----------- */}
                                    {selectedHarvestUnitInputType && selectedHarvestUnitInputType === 1 ?
                                        <><Form.Group controlId="formUnitHarvesting">
                                            <Form.Label className="text-dark">{t('UnitHarvesting')}</Form.Label>
                                            <ButtonGroup className="harvesting-unit-btn-group">
                                                {harvestUnitsGroup.map((radio2, i) => (
                                                    <ToggleButton
                                                        key={i}
                                                        id={radio2.name + i}
                                                        type='radio'
                                                        variant='outline-success'
                                                        name='radio2'
                                                        value={radio2.value}
                                                        checked={selectedHarvestUnit === radio2.value}
                                                        onChange={(e) => setSelectedHarvestUnit(Number(e.currentTarget.value))}
                                                    >
                                                        {radio2.name}
                                                    </ToggleButton>
                                                ))}
                                            </ButtonGroup>
                                        </Form.Group><div style={{ paddingLeft: '10px' }}>
                                                <Form.Group controlId="formUnitPcs">
                                                    <Form.Label className="text-dark">{t('UnitPcsArea')}:</Form.Label>
                                                    <NumberFormat
                                                        className={validate && unitPcs === 0 ? "form-control invalid-border" : "form-control"}
                                                        value={unitPcs}
                                                        decimalScale={0}
                                                        fixedDecimalScale={false}
                                                        onValueChange={(value) => {
                                                            value.floatValue ?
                                                                setUnitPcs(value.floatValue) : setUnitPcs(0);
                                                        }}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        isAllowed={valueMax}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group>
                                                {(selectedHarvestUnit === harvestConstants.WAGON_UNIT)
                                                    /* ----------- SELECTED UNIT: WAGON ----------- */
                                                    ?
                                                    <Form.Group controlId="formUnitTrailerVolume">
                                                        <Form.Label className="text-dark">{t('TrailerVolume')}:</Form.Label>
                                                        <NumberFormat
                                                            className={validate && trailerVolume === 0 ? "form-control invalid-border" : "form-control"}
                                                            value={trailerVolume}
                                                            decimalScale={2}
                                                            fixedDecimalScale={false}
                                                            onValueChange={(value) => {
                                                                value.floatValue ?
                                                                    setTrailerVolume(value.floatValue) : setTrailerVolume(0);
                                                            }}
                                                            displayType={'input'}
                                                            isAllowed={valueMax}
                                                            thousandSeparator={' '}
                                                            allowedDecimalSeparators={['.', ',']} />
                                                    </Form.Group>

                                                    /* ----------- SELECTED UNIT: BALES ----------- */
                                                    :
                                                    <Form.Group controlId="formUnitFreshContent">
                                                        <Form.Label className="text-dark">{t('UnitFreshContent')}:</Form.Label>
                                                        <NumberFormat
                                                            className={validate && freshContent === 0 ? "form-control invalid-border" : "form-control"}
                                                            value={freshContent}
                                                            decimalScale={2}
                                                            fixedDecimalScale={false}
                                                            onValueChange={(value) => {
                                                                value.floatValue ?
                                                                    setFreshContent(value.floatValue) : setFreshContent(0);
                                                            }}
                                                            displayType={'input'}
                                                            isAllowed={valueMax}
                                                            thousandSeparator={' '}
                                                            allowedDecimalSeparators={['.', ',']} />
                                                    </Form.Group>}

                                                {(selectedHarvestUnit === harvestConstants.WAGON_UNIT)
                                                    /* ----------- SELECTED UNIT: WAGON ----------- */
                                                    ?
                                                    <Form.Group controlId="formUnitVolumeWeight">
                                                        <Form.Label className="text-dark">{t('VolumeWeight')}:</Form.Label>
                                                        <NumberFormat
                                                            className={validate && volumeWeight === 0 ? "form-control invalid-border" : "form-control"}
                                                            value={volumeWeight}
                                                            decimalScale={2}
                                                            fixedDecimalScale={false}
                                                            onValueChange={(value) => {
                                                                value.floatValue ?
                                                                    setVolumeWeight(value.floatValue) : setVolumeWeight(0);
                                                            }}
                                                            displayType={'input'}
                                                            isAllowed={valueMax}
                                                            thousandSeparator={' '}
                                                            allowedDecimalSeparators={['.', ',']} />
                                                    </Form.Group>

                                                    /* ----------- SELECTED UNIT: BALES ----------- */
                                                    : ''}

                                                {/*total calculation*/}
                                                <Form.Group controlId="formTotal">
                                                    <Form.Label className="text-dark">{t('Total')}</Form.Label>
                                                    <NumberFormat
                                                        disabled
                                                        style={noInput}
                                                        suffix={' kg'}
                                                        className="form-control"
                                                        value={selectedHarvestUnit === harvestConstants.WAGON_UNIT ?
                                                            (trailerVolume * volumeWeight * unitPcs)
                                                            : (freshContent * unitPcs)}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group>
                                            </div></>

                                        : ''
                                    }
                                    {/*-----*/}
                                    <Form.Group>
                                        <Form.Label className="text-dark">{t('UnitDryMaterialPercentage')}:</Form.Label>
                                        <NumberFormat
                                            className={validate && dryMateriaContent === 0 ? "form-control invalid-border" : "form-control"}
                                            value={dryMateriaContent}
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            onValueChange={(value) => {
                                                value.floatValue ?
                                                    setDryMateriaContent(value.floatValue) : setDryMateriaContent(0);
                                            }}
                                            displayType={'input'}
                                            thousandSeparator={' '}
                                            isAllowed={valueMaxDryMaterialPercentage}
                                            allowedDecimalSeparators={['.', ',']} />
                                    </Form.Group>
                                    {selectedHarvestUnitInputType && selectedHarvestUnitInputType === 1 ?
                                        /* ----------- INPUT TYPE: KG ----------- */
                                        <><Form.Group>
                                            <Form.Label className="text-dark">{t('Amount_ha')}:</Form.Label>
                                            <NumberFormat
                                                className="form-control"
                                                value={(!multiSelect) ?
                                                    (
                                                        (selectedHarvestUnit === harvestConstants.WAGON_UNIT) ?
                                                            ((unitPcs * trailerVolume * volumeWeight) / harvestArea) * (dryMateriaContent / 100)
                                                            : ((unitPcs * freshContent) / harvestArea) * (dryMateriaContent / 100)
                                                    )
                                                    :
                                                    (
                                                        (selectedHarvestUnit === harvestConstants.WAGON_UNIT) ?
                                                            ((unitPcs * trailerVolume * volumeWeight) / totalArea) * (dryMateriaContent / 100)
                                                            : ((unitPcs * freshContent) / totalArea) * (dryMateriaContent / 100)
                                                    )}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(value) => {
                                                    value.floatValue ?
                                                        setDryMateriaPerHa(value.floatValue) : setDryMateriaPerHa(0);
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                suffix={'  ' + unit}
                                                allowNegative={false}
                                                disabled={true}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group><Form.Group>
                                                <Form.Label className="text-dark">{t('Amount_Area')}:</Form.Label>
                                                <NumberFormat
                                                    className="form-control"
                                                    value={(selectedHarvestUnit === harvestConstants.WAGON_UNIT) ?
                                                        (unitPcs * trailerVolume * volumeWeight) * (dryMateriaContent / 100)
                                                        : (unitPcs * freshContent) * (dryMateriaContent / 100)}
                                                    decimalScale={2}
                                                    fixedDecimalScale={false}
                                                    displayType={'input'}
                                                    thousandSeparator={' '}
                                                    suffix={'  ' + unit}
                                                    allowNegative={false}
                                                    disabled={true}
                                                    allowedDecimalSeparators={['.', ',']} />
                                            </Form.Group></>
                                        :
                                        /* ----------- INPUT TYPE: KG KA ----------- */
                                        !multiSelect ?
                                            <><Form.Group>
                                                <Form.Label className="text-dark">{t('Amount_ha')}:</Form.Label>
                                                <NumberFormat
                                                    className="form-control"
                                                    value={amount > 0 ? amount : ''}
                                                    decimalScale={2}
                                                    fixedDecimalScale={false}
                                                    onValueChange={(values) => {
                                                        if (values.floatValue && round(values.floatValue) !== round(amount)) {
                                                            if (values.floatValue) {
                                                                setAmount(values.floatValue)
                                                                if (totalArea)
                                                                    setAmountArea(values.floatValue * totalArea);
                                                                else
                                                                    setAmountArea(0);

                                                            }
                                                            else {
                                                                setAmount(0);
                                                                setAmountArea(0);
                                                            }
                                                        }
                                                    }}
                                                    displayType={'input'}
                                                    thousandSeparator={' '}
                                                    suffix={'  ' + unit}
                                                    allowNegative={false}
                                                    allowedDecimalSeparators={['.', ',']} />
                                            </Form.Group><Form.Group>
                                                    <Form.Label className="text-dark">{t('Amount_Area')}:</Form.Label>
                                                    <NumberFormat
                                                        className="form-control"
                                                        value={amount * harvestArea > 0 ? amount * harvestArea : ''}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        onValueChange={(values) => {
                                                            if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                                                if (values.floatValue) {

                                                                    if (harvestArea > 0) {
                                                                        setAmount(values.floatValue / harvestArea);
                                                                    }
                                                                    else
                                                                        setAmount(0);

                                                                    setAmountArea(values.floatValue);
                                                                }
                                                                else {
                                                                    setAmount(0);
                                                                    setAmountArea(0);
                                                                }
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        suffix={'  ' + unit}
                                                        allowNegative={false}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group></>
                                            :
                                            <><Form.Group>
                                                <Form.Label className="text-dark">{t('Amount_ha')}:</Form.Label>
                                                <NumberFormat
                                                    className="form-control"
                                                    value={amount > 0 ? amount : ''}
                                                    decimalScale={2}
                                                    fixedDecimalScale={false}
                                                    onValueChange={(values) => {
                                                        if (values.floatValue && round(values.floatValue) !== round(amount)) {
                                                            if (values.floatValue) {
                                                                setAmount(values.floatValue)

                                                                if (harvestArea)
                                                                    setAmountArea(values.floatValue * harvestArea);
                                                                else
                                                                    setAmountArea(0);

                                                            }
                                                            else {
                                                                setAmount(0);
                                                                setAmountArea(0);
                                                            }
                                                        }
                                                    }}
                                                    displayType={'input'}
                                                    thousandSeparator={' '}
                                                    suffix={'  ' + unit}
                                                    allowNegative={false}
                                                    allowedDecimalSeparators={['.', ',']} />
                                            </Form.Group><Form.Group>
                                                    <Form.Label className="text-dark">{t('Amount_Area')}:</Form.Label>
                                                    <NumberFormat
                                                        className="form-control"
                                                        value={totalArea && (amount * totalArea) > 0 ? amount * totalArea : ''}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        onValueChange={(values) => {
                                                            if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                                                if (values.floatValue) {

                                                                    if (totalArea > 0) {
                                                                        setAmount(values.floatValue / totalArea);
                                                                    }
                                                                    else
                                                                        setAmount(0);

                                                                    setAmountArea(values.floatValue);
                                                                }
                                                                else {
                                                                    setAmount(0);
                                                                    setAmountArea(0);
                                                                }
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        thousandSeparator={' '}
                                                        suffix={'  ' + unit}
                                                        allowNegative={false}
                                                        allowedDecimalSeparators={['.', ',']} />
                                                </Form.Group></>
                                    }

                                </div>
                                /* ----------- UNITTYPE: OTHER --> only kg ----------- */
                                : !multiSelect ?
                                    <div>
                                        <Form.Group controlId="formArea">
                                            <Form.Label className="text-dark">{t('Yield_HarvestArea')}:</Form.Label>
                                            <NumberFormat
                                                className={!validHarvestArea ? "form-control invalid-border" : "form-control"}
                                                placeholder={' ha'}
                                                value={harvestArea >= 0 ? harvestArea : ''}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(value) => {
                                                    if (value.floatValue !== null && value.floatValue
                                                        !== harvestArea) {
                                                        validateHarvestArea(value.floatValue);
                                                        setHarvestArea(value.floatValue);
                                                    }
                                                    if (!value.floatValue) {
                                                        setValidHarvestArea(false);
                                                    }
                                                }}
                                                suffix={'  ha'}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                isAllowed={valueMaxArea}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="formAmount">
                                            <Form.Label className="text-dark">{t('Amount_ha')}:</Form.Label>
                                            <NumberFormat
                                                className={validate && amount === 0 ? "form-control invalid-border" : "form-control"}
                                                value={amount > 0 ? amount : ''}
                                                placeholder={' ' + unit}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(values) => {
                                                    if (values.floatValue && round(values.floatValue) !== round(amount)) {
                                                        if (values.floatValue) {
                                                            setAmount(values.floatValue)

                                                            if (harvestArea)
                                                                setAmountArea(values.floatValue * harvestArea);
                                                            else
                                                                setAmountArea(0);
                                                        }
                                                        else {
                                                            setAmount(0);
                                                            setAmountArea(0);
                                                        }
                                                    }
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                suffix={'  ' + unit}
                                                allowNegative={false}
                                                isAllowed={valueMax}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="formAmountHa">
                                            <Form.Label className="text-dark">{t('Amount_Area')}:</Form.Label>
                                            <NumberFormat
                                                className="form-control"
                                                value={amount * harvestArea > 0 ? amount * harvestArea : ''}
                                                placeholder={' ' + unit}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(values) => {
                                                    if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                                        if (values.floatValue) {
                                                            if (harvestArea > 0) {
                                                                setAmount(values.floatValue / harvestArea);
                                                            }
                                                            else
                                                                setAmount(0);

                                                            setAmountArea(values.floatValue);
                                                        }
                                                        else {
                                                            setAmount(0);
                                                            setAmountArea(0);
                                                        }
                                                    }
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                suffix={'  ' + unit}
                                                allowNegative={false}
                                                isAllowed={valueMax}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                    </div>
                                    :
                                    <div>
                                        <Form.Group controlId="formArea">
                                            <Form.Label className="text-dark">{t('Yield_HarvestArea')}:</Form.Label>
                                            <NumberFormat
                                                className="form-control"
                                                value={totalArea}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(value) => {
                                                    if (value.floatValue) {
                                                        validateHarvestArea(value.floatValue);
                                                        setHarvestArea(value.floatValue);
                                                    }
                                                    else {
                                                        setAmount(0);
                                                    }
                                                }}
                                                displayType={'input'}
                                                readOnly
                                                thousandSeparator={' '}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="formAmount">
                                            <Form.Label className="text-dark">{t('Amount_ha')}:</Form.Label>
                                            <NumberFormat
                                                className={validate && amount === 0 ? "form-control invalid-border" : "form-control"}
                                                value={amount > 0 ? amount : ''}
                                                placeholder={' ' + unit}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(values) => {
                                                    if (values.floatValue && round(values.floatValue) !== round(amount)) {
                                                        if (values.floatValue) {
                                                            setAmount(values.floatValue)
                                                            if (totalArea)
                                                                setAmountArea(values.floatValue * totalArea);
                                                            else
                                                                setAmountArea(0);
                                                        }
                                                        else {
                                                            setAmount(0);
                                                            setAmountArea(0);
                                                        }
                                                    }
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                suffix={'  ' + unit}
                                                allowNegative={false}
                                                isAllowed={valueMax}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="formAmountHa">
                                            <Form.Label className="text-dark">{t('Amount_Area')}:</Form.Label>
                                            <NumberFormat
                                                className="form-control"
                                                value={totalArea && (amount * totalArea) > 0 ? amount * totalArea : ''}
                                                placeholder={' ' + unit}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(values, e) => {
                                                    if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                                        if (values.floatValue) {
                                                            if (totalArea > 0) {
                                                                setAmount(values.floatValue / totalArea);
                                                            }
                                                            else
                                                                setAmount(0);

                                                            setAmountArea(values.floatValue);
                                                        }
                                                        else {
                                                            setAmount(0);
                                                            setAmountArea(0);
                                                        }
                                                    }
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                suffix={'  ' + unit}
                                                allowNegative={false}
                                                isAllowed={valueMax}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                    </div>
                            }
                            <Form.Group controlId="formStorage">
                                <Form.Label className="text-dark">{t('Yield_Storage')}</Form.Label>
                                {storagesIsLoading ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : ''}
                                <Select
                                    className={validate && (!selectedStorage || !selectedStorage.id) ? "text-dark invalid-border" : "text-dark"}
                                    disabled={!storagesIsLoading && selectedStorage && selectedStorage.id ? false : true}
                                    value={drawStorages && selectedStorage ? drawStorages.find(s => s.value === selectedStorage.id) : { label: textt, value: null }}
                                    onChange={setSelectedStorage}
                                    options={drawStorages}
                                    noOptionsMessage={({ inputValue }) => !inputValue ? t('NoStorages') : ''}
                                />
                                <Button style={{ marginTop: '10px' }} variant="success"
                                    disabled={selectedProduct && selectedProduct.product ? false : true}
                                    onClick={() => {
                                        setSiloShow(true);
                                    }}>{t('AddNewStorage')}</Button>

                            </Form.Group>
                            <Form.Group controlId="formDate">
                                <Form.Label className="text-dark">{t('Date')}</Form.Label>
                                <Form.Control type="text" onClick={() => setCalendarShow(true)} placeholder={t('Date')} value={dayJsFormatDate(date)} onChange={() => { }} />
                            </Form.Group>
                            {multiSelect ?
                                <Accordion className="show-chosen-plots-accordion">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header><b>{t('ShowChosenPlots')}</b></Accordion.Header>
                                        <Accordion.Body>
                                            <div className="plot-list">
                                                {props.selectEvent.selectedPlots.map((plot, i) =>
                                                    <div key={i}>
                                                        <b>{plot.name} - {plot.apChar}</b> ({plot.area} ha)
                                                    </div>
                                                )}
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                : ''}
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    {props.selectEvent.selectedElement && buttonWriteShow === true ?
                        <Button variant='outline-danger' disabled={saveInProgress} className="footer-button-left"
                            onClick={() => deleteHarvest()}>{saveInProgress ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : t('Delete')}</Button>
                        : ''}

                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Cancel')}</Button>

                    {buttonWriteShow === true ?
                        <Button disabled={saveInProgress || checkSaveDisabled()} variant="success" className="btn-show-more" onClick={() => {
                            setValidation(true);
                            saveHarvest();
                        }}>{saveInProgress ? <Spinner as="span" animation="border" size="sm" role="status" variant="secondary" /> : t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
            <CalendarModal
                show={calendarShow}
                onHide={() => setCalendarShow(false)}
                onChange={setDate}
                selecteddate={date}
            />

            {siloShow ?
                <SiloModal
                    showSilo={siloShow}
                    onHideSilo={() => setSiloShow(false)}
                    selectedProduct={selectedProduct.product}
                    defaultName={selectedProduct.explanation}
                    buttonWriteShow={buttonWriteShow}
                    existingStorages={storages}
                    saveSilo={(selectedValu) => {

                        setStoragesIsLoading(true);

                        getContentWithAction(auth.getAccessToken(), 'resource', 'storages', {}).then((storages) => {
                            setStorages(storages ? storages : []);
                            setDrawStorages(storages ? storages.filter(s => Number(s.product) === Number(selectedProduct.product)) : []);
                            setSelectedStorage(storages && selectedValu ?
                                storages.find(s => Number(s.value) === Number(selectedValu)) : []
                            );

                            alert.show(t('SavedSuccesfully'), { type: 'success' });
                            setStoragesIsLoading(false);
                        }).catch(e => {
                            alert.show(t('SavedFailed'), { type: 'error' });
                        });
                    }}
                />
                : ''}


        </>
    );
}

export default HarvestModal;