import React, { useContext } from 'react';
import AccordionContext from 'react-bootstrap/AccordionContext'
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { ICONS } from './img/icons'
import Icon from './img/Icon'

const iconStyle = {
    display: 'inline-block',
    verticalAlign: 'top',
    marginRight: '5px',
    marginTop: '3px'
}

const inventoryIconStyle = {
    display: 'inline-block',
    marginRight: '5px',
    marginBottom: '8px'
}

export const GetLogoutIcon = () =>
(
    <Icon icon={ICONS.LOGOUT} color='black' size='25' style={iconStyle} />
);

export const GetGroupIcon = () =>
(
    <Icon icon={ICONS.GROUP} color='black' size='22' style={iconStyle} />
);

export const GetPlantIcon = () =>
(
    <Icon icon={ICONS.PLANT} color='black' size='22' style={iconStyle} />
);

export const GetPlotsIcon30 = () =>
(
    <Icon icon={ICONS.PLOTS} color='black' size='28' style={iconStyle} />
);

export const GetPlotsIcon = () =>
(
    <Icon icon={ICONS.PLOTS} color='black' size='22' style={iconStyle} />
);

export const GetEditIcon = () =>
(
    <Icon icon={ICONS.EDIT} color='black' size='22' style={iconStyle} />
);


export const GetFertilityIcon = () =>
(
    <Icon icon={ICONS.FERTILITYSAMPLE} color='black' size='22' style={iconStyle} />
);

export const GetInventoryIcon = () =>
(
    <Icon icon={ICONS.INVENTORY} color='black' size='34' style={inventoryIconStyle} />
);

export function OperationCardToggle({ children, eventKey, callback, classname }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <button
            type="button"
            className={classname + " text-dark btn btn-text section-button"}
            onClick={() => decoratedOnClick()}
        >
            {children}
        </button>
    );
}